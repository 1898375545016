export const data = [
  {
    label: "Front Office",
    checkbooks: [
      "F.O. Mng.",
      "F.O. Supervisor",
      "F.O. Agent",
      "Bell Desk",
      "Operator",
    ],
  },
  {
    label: "Sales & Reservation",
    checkbooks: [
      "Reservation Mng.",
      "Reservation Agent",
      "Revenue Mng.",
      "Sales Mng.",
      "Sales Agent",
    ],
  },
  {
    label: "Accounts",
    checkbooks: [
      "Finance Mng.",
      "Accounts",
      "AR",
      "Income Auditor",
      "Night Auditor",
    ],
  },
  {
    label: "Others",
    checkbooks: ["Engineering", "F & B", "Housekeeping", "Management", "IT"],
  },
];

export const textFields = [
  {
    name: "clockNum",
    label: "Clock Number",
    errorKey: "clockNum",
  },
  {
    name: "name",
    label: "Employee's name",
    errorKey: "name",
  },
  {
    name: "position",
    label: "Position",
    errorKey: "position",
  },
  {
    name: "special",
    label: "Special Authorities",
    errorKey: "special",
  },
];

export const databases = ["Live", "Training", "Cashier"];

export const hotels = [
  "Grand Millennium Tabuk",
  "Grand Millennium Gizan",
  "Millennium Hail",
];

export const departments = [
  "IT",
  "Executive Office",
  "Finance",
  "Human Resources",
  "Engineering",
  "Front Office",
  "Housekeeping",
  "F&B",
  "Kitchen",
  "Stewarding",
  "SPA",
  "SAL&MKT&RES",
  "Security",
  "Purchasing",
  "Laundry",
  "Revenue",
];
