export const textFields = [
    {
      name: "clockNum",
      label: "Clock Number",
      errorKey: "clockNum",
    },
    {
      name: "name",
      label: "Employee's name",
      errorKey: "name",
    },
    {
      name: "position",
      label: "Position",
      errorKey: "position",
    },
    {
      name: "special",
      label: "Special Authorities",
      errorKey: "special",
    },
  ];
  
  export const hotels = [
    "Grand Millennium Tabuk",
    "Grand Millennium Gizan",
    "Millennium Hail",
  ];
  
  export const authOptions = [
    "F&B Manager",
    "Cashier",
    "Outlet Managersr",
    "Mymicros Portal",
    "Order Taker",
    "Waiter",
  ];
  
  export const departments = [
    "IT",
    "Executive Office",
    "Finance",
    "Human Resources",
    "Engineering",
    "Front Office",
    "Housekeeping",
    "F&B",
    "Kitchen",
    "Stewarding",
    "SPA",
    "SAL&MKT&RES",
    "Security",
    "Purchasing",
    "Laundry",
    "Revenue",
  ];
  