export const textFields = [
  {
    name: "itemCode",
    label: "Item Name",
    errorKey: "itemCode",
  },
  {
    name: "menuName",
    label: "Menu Name",
    errorKey: "menuName",
  },
  {
    name: "outlet",
    label: "Outlet",
    errorKey: "outlet",
  },
  {
    name: "majorGroup",
    label: "Major Group",
    errorKey: "majorGroup",
  },
  {
    name: "familyGroup",
    label: "Family Group",
    errorKey: "familyGroup",
  },
  {
    name: "price",
    label: "Price (SAR)",
    type: "number",
    errorKey: "price",
  },
];

export const hotels = [
  "Grand Millennium Tabuk",
  "Grand Millennium Gizan",
  "Millennium Hail",
];
